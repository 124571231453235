<template>
  <Page color="success" title="Termine">
    <template slot="icons">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" icon color="white" v-bind="attrs" v-on="on">
            <v-icon>mdi-cogs</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch inset v-model="$root.settings.showIcal"></v-switch>
              </v-list-item-action>
              <v-list-item-title
                @click="$root.settings.showIcal = !$root.settings.showIcal"
                >ical-links anzeigen</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>

    <template slot="extension">
      <v-tabs
        slider-color="white"
        background-color="success darken-1"
        :align-with-title="!$vuetify.breakpoint.xs"
      >
        <v-tab v-if="!$vuetify.breakpoint.mobile" :to="{ name: 'EventList' }">
          Liste
        </v-tab>
        <v-tab :to="{ name: 'EventCalendar' }"> Kalender</v-tab>
        <v-tab :to="{ name: 'Holidays' }"> Ferien</v-tab>
      </v-tabs>
    </template>

    <template slot="help">
      <h3>Neuerungen</h3>
      <ul>
        <li>
          Neu steht die Terminliste auch in einer Kalenderansicht zur Verfügung.
        </li>
      </ul>
    </template>
    <router-view></router-view>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Events",
});
</script>
